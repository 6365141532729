import React from 'react';
import styled from 'styled-components';
import Image from './images/headerImage';
import MobileImage from './images/headerImageMobile';

const Header = styled.header`
  .header_image {
    @media all and (max-width: 559px) {
      display: none;
    }
  }
  .header_image_mobile {
    display: none;

    @media all and (max-width: 559px) {
      display: block;
    }
  }
`;

const HeaderArea = () => {
  return (
    <Header>
      <div
        className="header_image"
        style={{
          maxWidth: `1500px`,
          marginRight: `auto`,
          marginLeft: `auto`,
        }}
      >
        <Image />
      </div>
      <div
        className="header_image_mobile"
        style={{
          maxWidth: `1500px`,
          marginRight: `auto`,
          marginBottom: `1.45rem`,
          marginLeft: `auto`,
        }}
      >
        <MobileImage />
      </div>
    </Header>
  );
};

export default HeaderArea;
