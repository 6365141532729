import React from 'react';
import { Link } from 'gatsby';

const Tags = ({ tabSwith, data }) => {
  let array = [];
  data.map(d => (array = array.concat(d.node._meta.tags)));
  const newTags = array.filter((x, i, self) => {
    return self.indexOf(x) === i;
  });
  return (
    <div
      className="tags"
      role="tabpanel"
      id="tags"
      aria-hidden={tabSwith.tab !== 'tags'}
    >
      <p className="mobile_section_title">Tags</p>
      <ul>
        {newTags.map(tag => {
          const tagLowerCase = tag.toLowerCase();
          return (
            <p key={tag}>
              <Link to={`/tags/${tagLowerCase}/`}>#{tag}</Link>
            </p>
          );
        })}
      </ul>
    </div>
  );
};

export default Tags;
