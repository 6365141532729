import React, { useCallback, useState } from 'react';
import SEO from '../components/seo';
import styled from 'styled-components';
import HeaderArea from '../components/header';
import Latest from '../components/latest';
import Category from '../components/category';
import Profile from '../components/profile';
import Tags from '../components/tags';
import FooterArea from '../components/footer';
import '../scss/home.scss';
import '../scss/home_responsible.scss';
import { graphql } from 'gatsby';

export const query = graphql`
  {
    prismic {
      allArticles {
        edges {
          node {
            article_title
            posted_at
            updated_at
            _meta {
              tags
              uid
            }
            categories {
              category {
                _linkType
                ... on PRISMIC_Category {
                  name
                }
              }
            }
          }
        }
      }
      allCategorys {
        edges {
          node {
            name
            _meta {
              id
            }
          }
        }
      }
    }
  }
`;

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Container = styled.main`
  flex: 1;
  display: flex;
  margin-top: 1.45rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
`;

const NavWrapper = styled.nav`
  width: 30vw;
  border-right: solid 2px black;

  @media all and (max-width: 559px) {
    display: none;
  }
`;

const Section = styled.section`
  width: 70vw;

  @media all and (max-width: 559px) {
    width: 90vw;
  }
`;

const App = ({ data }) => {
  const articleData = data.allArticles.edges;
  const categoryData = data.allCategorys.edges;
  const [state, setState] = useState({
    tab: 'latest',
  });

  const handleClick = useCallback(e => {
    const element = e.currentTarget;
    const tabState = element.getAttribute('aria-controls');

    setState({
      tab: tabState,
    });
  }, []);

  return (
    <>
      <NavWrapper>
        <div className="nav_area">
          <ul role="group">
            <li role="presentation">
              <button
                role="tab"
                aria-controls="latest"
                aria-selected={state.tab === 'latest'}
                onClick={handleClick}
              >
                Latest
              </button>
            </li>
            <li role="presentation">
              <button
                role="tab"
                aria-controls="category"
                aria-selected={state.tab === 'category'}
                onClick={handleClick}
              >
                Category
              </button>
            </li>
            <li role="presentation">
              <button
                role="tab"
                aria-controls="profile"
                aria-selected={state.tab === 'profile'}
                onClick={handleClick}
              >
                Profile
              </button>
            </li>
            <li role="presentation">
              <button
                role="tab"
                aria-controls="tags"
                aria-selected={state.tab === 'tags'}
                onClick={handleClick}
              >
                Tags
              </button>
            </li>
          </ul>
        </div>
      </NavWrapper>
      <Section>
        <div className="section">
          <Latest tabSwith={state} data={articleData} />
          <Category tabSwith={state} data={categoryData} />
          <Profile tabSwith={state} />
          <Tags tabSwith={state} data={articleData} />
        </div>
      </Section>
    </>
  );
};

const Home = props => {
  const data = props.data.prismic;
  return (
    <Body>
      <SEO
        title="Home"
        url={props.location.href}
        description="33歳 京都で小さな会社をやっています。「経験からしか得られない知見を後に続く人の道標に」をコンセプトにブログを書いています。"
      />
      <HeaderArea />
      <Container>
        <App data={data} />
      </Container>
      <FooterArea />
    </Body>
  );
};

export default Home;
