import React from 'react';
import { Link } from 'gatsby';
import { dateSort } from '../utils/sort';

const Latest = ({ tabSwith, data }) => {
  const filteredArticles = data.filter(d => {
    return d.node._meta.uid !== 'privacy' && d.node._meta.uid !== 'law';
  });
  const dateSortArticle = filteredArticles.sort(dateSort());
  const latestArticle = dateSortArticle.slice(0, 3);
  return (
    <div
      className="latest"
      role="tabpanel"
      id="latest"
      aria-hidden={tabSwith.tab !== 'latest'}
    >
      <p className="mobile_section_title">Latest</p>
      <ul>
        {latestArticle.map(data => {
          const articleTitle = data.node.article_title[0].text;
          const link = data.node._meta.uid;
          const postedAt = data.node.posted_at;
          const updatedAt = data.node.updated_at;
          const tags = data.node._meta.tags;
          return (
            <li key={link}>
              <div className="article_title">
                <Link to={`/${link}/`}>{articleTitle}</Link>
              </div>
              <div className="day">
                <span className="posted_at">{postedAt}</span>
                {!!updatedAt && (
                  <span className="updated_at">更新:{updatedAt}</span>
                )}
              </div>
              {tags.length > 0 &&
                tags.map(tag => {
                  const tagLowerCase = tag.toLowerCase();
                  return (
                    <span key={tag} className="tags">
                      <Link to={`/tags/${tagLowerCase}/`}>#{tag}</Link>
                    </span>
                  );
                })}
            </li>
          );
        })}
        <li>
          <div className="article_title">
            <Link to="/articles/">Read more...</Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Latest;
