import React from 'react';
import { Link } from 'gatsby';

const Category = ({ tabSwith, data }) => {
  return (
    <div
      className="category"
      role="tabpanel"
      id="category"
      aria-hidden={tabSwith.tab !== 'category'}
    >
      <p className="mobile_section_title">Category</p>
      <ul>
        {data.map(c => {
          const category = c.node.name;
          const categoryLowerCase = category.toLowerCase();
          const initialUpperCategory =
            category.slice(0, 1).toUpperCase() + category.slice(1);
          return (
            <li key={category}>
              <Link to={`/categories/${categoryLowerCase}/`}>
                {initialUpperCategory}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Category;
