import React from 'react';
import Image from './images/profileImage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faInstagram,
  faGithub,
  faAirbnb
} from '@fortawesome/free-brands-svg-icons';

library.add(faTwitter, faInstagram, faGithub, faAirbnb);

const Profile = props => {
  return (
    <div
      className="profile"
      role="tabpanel"
      id="profile"
      aria-hidden={props.tabSwith.tab !== 'profile'}
    >
      <p className="mobile_section_title">Profile</p>
      <div className="profile-thumb">
        <Image />
      </div>
      <div className="profile-message">
        <p>35歳 京都で小さな会社をやっています。</p>
        <p>京都で一棟貸の宿も経営しています。</p>
        <p>「経験からしか得られない知見を後に続く人の道標に」をコンセプトにブログを書いています。</p>
      </div>
      <div className="profile-sns">
        <a
          className="twitter"
          href="https://twitter.com/bonjour_ken"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          className="instagram"
          href="https://www.instagram.com/Bonjour_Ken/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          className="github"
          href="https://github.com/aguriken"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          className="airbnb"
          href="https://airbnb.com/h/megumian-nishijin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faAirbnb} />
        </a>
      </div>
    </div>
  );
};

export default Profile;
